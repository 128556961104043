<template>
  <div
    class="cursor-pointer rounded-lg border border-gray-600 bg-white p-4 hover:bg-gray-50"
    data-track-slot-change="true"
    @click="handleChange()">
    <div class="flex items-center justify-between">
      <div class="text-base font-medium text-gray-900">
        <p v-if="title">{{ title }}</p>
        <p>{{ transformDateStringToWeekdayDayMonthYear(date) }}</p>
        <p>
          <span>{{ transformDateStringToTime(date) }} Uhr</span>
          <template v-if="duration">
            <span class="mx-2 text-brick-700">|</span><span>{{ formatServiceDuration(duration) }}</span>
          </template>
        </p>
      </div>
      <button class="flex items-center border-l-2 border-gray-200 px-4 font-medium">Ändern <SvgoChevronRight /></button>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  title?: string;
  date: string;
  duration?: number;
}>();

const emits = defineEmits<{
  change: [];
}>();

function handleChange(): void {
  emits("change");
}
</script>

<style scoped></style>
